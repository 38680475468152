
import { useEffect, useState } from 'react';
import './App.css';


function App() {


  return (
    <main>

      <div id="text">david eriksson


        <div id="social">
          <a href="https://www.linkedin.com/in/david-eriksson-468019153/" target="_blank" rel="noreferrer noopener">linkedin</a>
          <a href="mailto:d@videriksson.com">email</a>
        </div>


      </div>


    </main>
  );
}

export default App;
